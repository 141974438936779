<template>
  <div v-if="isCardTabLoaded" :class="$style.tabContainer">
    <Icons />

    <Header />

    <Activation :class="$style.activation" v-if="showPayButton && isCardTabLoaded && !widgetStatus.isActive" />

    <div
      v-if="(widgetStatus.isActive && isFailLoadingSettings) || (widgetStatus.isActive && isfailLoadingOrganizations)"
      :class="$style.errorLoadingData">
      {{ errorLoading }}
    </div>

    <template v-if="isCardTabLoaded && widgetStatus.isActive && isEntityValid">
      <ApiTariff v-if="isApiTariff" :style="{ margin: '10px' }" />
      <template v-else>
        <TabWrapper />
      </template>
    </template>

    <Alert v-else type="error" title="Ошибка" :class="$style.authAlert">
      <template v-slot:text>
        <div v-if="isCompany">
          <span>Виджет не работает в карточке компании</span>
        </div>
        <div v-else-if="isContact">
          <span>Виджет не работает в карточке контакта</span>
        </div>
        <div v-else>
          <span>Неизвестная ошибка</span>
        </div>
      </template>
    </Alert>
  </div>
  <div v-else :class="$style.tabContainer">
    <Header />
    <Preload v-if="isAmoAuthorized" />
    <Alert v-else type="error" title="Нет доступа к авторизации" :class="$style.authAlert">
      <template v-slot:text>
        <div v-if="isAdmin">
          Для того, чтобы выдать доступы к авторизации необходимо перейти по
          <a :href="`https://www.amocrm.ru/oauth?client_id=${partnerData.clientId}`" target="_blank">ссылке</a>
        </div>
        <div v-else>
          <span>Для того, чтобы выдать доступы к авторизации необходимо перейти по </span>
          <span :class="$style.disabledAuthLink">ссылке</span>
          <span> (только для администраторов)</span>
        </div>
      </template>
    </Alert>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  import Icons from "@/gnzs-controls/IconsSprite";
  import TabWrapper from "@/components/Tab/TabWrapper";
  import Preload from "@/components/Tab/TabPreload/TabPreload";
  import Activation from "@/components/Settings/Activation";
  import ApiTariff from "@/components/Settings/ApiTariff";
  import Header from "./header";
  import Alert from "@/gnzs-controls/Alert";
  import { GNZS_WIDGET_ID, API_TARIFF_ID } from "@/store/constants";

  export default {
    data() {
      return {
        errorLoading: "Ошибка загрузки данных. Попробуйте позже еще раз",
      };
    },
    computed: {
      ...mapGetters({
        isCardTabLoaded: "isCardTabLoaded",
        isOrderCreated: "order/isCreated",
        widgetStatus: "widgetStatus",
        partnerData: "partnerData",
        isAdmin: "isAdmin",
        isAmoAuthorized: "isAmoAuthorized",
        isFailLoadingSettings: "settings/failLoading",
        isfailLoadingOrganizations: "organizations/failLoading",
        entity: "order/entity",
        widgetId: "widgetId",
        tariffId: "features/getTariffId",
      }),
      isApiTariff() {
        return this.tariffId == API_TARIFF_ID;
      },
      showPayButton() {
        return this.widgetId == GNZS_WIDGET_ID;
      },
      isEntityValid() {
        return !this.isCompany && !this.isContact;
      },
      isCompany() {
        return this.entity.entityType == 3;
      },
      isContact() {
        return this.entity.entityType == 1;
      },
    },
    components: {
      Icons,
      Preload,
      TabWrapper,
      Activation,
      Header,
      Alert,
      ApiTariff,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>
