/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2021
 *
 * Vuex Actions заказа
 */

import { getOrder, saveOrder } from "@/api/docflow-api";
import { save1cOrder, copy1cOrder, realization1c, evotor1c, calcAutoDiscount, extraAction } from "@/api/1c-api";
import { deepCloneByJson } from "@/utilities/deepClone";
import * as amoApi from "@/api/amocrm-api";
import * as orderUtil from "@/utilities/order";
import { orderData } from "./state";
import {
  entityHasLinkedCompany,
  entityHasLinkedContact,
  showError,
  generateEntityLink,
  getCfValue,
} from "@/utilities/amo";

import { showModal } from "@/utilities/order";

import { paysActions } from "@/store/substores/paysDetail";
import { massUseActions } from "@/store/substores/massUse";
import { orderCopyActions } from "@/store/substores/orderCopy";
import { orderUpdateActions } from "@/store/substores/orderUpdate";

import Vue from "vue";
import moment from "moment";

export default {
  async runExtraAction({ getters, dispatch, commit }, code) {
    try {
      commit("setRunningAction", { code, value: true });

      const respOrder = await extraAction({
        code,
        data: getters.orderData,
      });

      if (respOrder.data?.error) {
        showModal("Ошибка", respOrder.data.error);
      } else {
        if (respOrder.data && Object.keys(respOrder.data).length > 0) {
          commit("replaceData", respOrder.data);
          dispatch("calcOrder");
          commit("calcOrderTotal");
        }
      }
    } catch (e) {
      if (e?.response?.status == 404) showModal("Ошибка", "Действие не назначено");
      console.debug("Ошибка выполнения дополнительного действия ", { e });
    } finally {
      commit("setRunningAction", { code, value: false });
    }
  },

  async load({ commit, dispatch, rootGetters }) {
    try {
      const entityData = rootGetters["order/entity"];
      const respOrder = await getOrder(entityData.entityId);

      if (!respOrder.data.order_custom_fields) {
        Vue.set(respOrder.data, "order_custom_fields", []);
      }

      commit("setOrderData", { data: respOrder.data, rootGetters });
      commit("setRootValue", { key: "isCreated", value: true });

      rootGetters["extension/onOrderLoaded"]?.call(null);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения данных о заказе`, e);

      const newOrderData = orderUtil.getNewOrderDataWithDefaultsSettings({
        orderData,
        defaultsSettings: rootGetters["settings/defaults"],
        organizations: rootGetters["organizations/items"],
        organizationsDefaults: rootGetters["settings/organizationsDefaults"],
        managerDefaults: rootGetters["settings/managerDefaults"],
        amoUserId: rootGetters["amoUserId"],
        stocks: rootGetters["stocks/items"],
        currencies: rootGetters["localSettings/currencies"],
      });

      commit("setOrderData", { data: newOrderData, rootGetters });

      dispatch("setCustomFieldsDefaults");
      dispatch("partners/loadContragentsByPartnerId", rootGetters, { root: true });
    }
  },

  setOwnerAgreement({ commit, rootGetters }, checkCreated = true) {
    const agreements = rootGetters["agreements/items"];
    const primary = rootGetters["settings/defaults"].ownerAgreementFirst;
    commit("setOwnerAgreement", { checkCreated, agreements, primary });
  },

  setCustomFieldsDefaults({ dispatch, rootGetters }) {
    const settingsOrderCFs = rootGetters["settings/orderCFs"];

    if (settingsOrderCFs?.length) {
      settingsOrderCFs.forEach((cf) => {
        if (cf?.default) {
          if (["text", "int", "bool"].includes(cf.type)) {
            dispatch("setOrderCf", { attribute: cf.attribute, value: cf.default });
          } else if (cf.type == "date") {
            const value = new Date(moment(cf.default, "DD.MM.YYYY 0:00:00"));
            dispatch("setOrderDateCf", { attribute: cf.attribute, value });
          } else {
            dispatch("setOrderCf", { attribute: cf.attribute, code: cf.code, value: cf.default });
          }
        }
      });
    }
  },

  onChangeMeasure: ({ commit }, payload) => {
    commit("onChangeMeasure", payload);
    commit("calcProcuct", { product: payload.product });
    commit("calcOrderTotal");
  },

  setContractParams: ({ commit, dispatch, rootGetters }) => {
    const contract = rootGetters["contracts/contractById"](rootGetters["order/contractId"]);
    if (contract?.nds == null) return;
    const nds = isNaN(parseInt(contract?.nds)) ? 0 : parseInt(contract?.nds);
    if (isNaN(parseInt(contract?.nds))) {
      commit("setNds", 0);
    }

    commit("setProductsNds", { nds, productDataItems: null, defaultNdsVal: 0 });

    const priceTypeId = contract?.price_type_id;

    if (priceTypeId) {
      commit("recalcPrices", { priceTypeId, rootGetters });
    }

    dispatch("calcOrder");
    commit("calcOrderTotal");
  },

  setTabMode: ({ commit }, value) => commit("setTabMode", value),

  copyOrder({ state, rootGetters }) {
    const currentUser = rootGetters["amoUser"];

    (state.data.current_user_id = `${currentUser.id}`),
      (state.data.current_user_name = `${currentUser.name}`),
      (state.data.current_user_phone = `${currentUser.phone}`),
      (state.data.current_user_emall = `${currentUser.email}`),
      copy1cOrder(state.data, {});
  },

  async update1c({ dispatch }) {
    await dispatch("updateOrderByEntityData");
    await dispatch("save1c", false);
  },

  async update1cWithCompany({ dispatch }) {
    await dispatch("load");
    await dispatch("updateOrderByEntityData");
    dispatch("setOrganizationNullData");
    await dispatch("save1c", false);
  },

  setOrganizationNullData({ state }) {
    const nullId = "00000000-0000-0000-0000-000000000000";

    state.data.request_source = "iframe";

    if (!state.data.organization_id) state.data.organization_id = nullId;
    if (!state.data.organization_account_id) state.data.organization_account_id = nullId;
  },

  async sendToEvotor({ getters, commit, rootGetters }) {
    /*
     Отправка данных заказа в Эвотор
    */
    commit("setRootValue", { key: "isSavingEvotor", value: true });

    let widget = rootGetters["widgetRef"],
      targets = rootGetters["settings/showErrors"], //где отображать ошибки
      leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание
    try {
      const response = await evotor1c(getters.entityData);
      if (response.data?.message == "success") {
        //showNoty({widget,header: 'Эвотор',text: 'Заказ успешно отправлен'})
      } else {
        showError({
          widget,
          targets,
          leadId,
          header: `1С ${rootGetters["partnerName"]}`,
          text: `Ошибка отправления в Эвотор`,
        });
        console.debug(`Ошибка отправления в Эвотор`, response);
      }
    } catch (e) {
      console.debug(`Ошибка отправления в Эвотор`, e.response);
      showError({
        widget,
        targets,
        leadId,
        header: `1С ${rootGetters["partnerName"]}`,
        text: `Ошибка отправления в Эвотор. [${e.response.status}]: ${e.response.statusText}`,
      });
    }
    commit("setRootValue", { key: "isSavingEvotor", value: false });
  },

  async save({ state, commit, rootGetters }, isWithout1cSaving = true) {
    try {
      if (isWithout1cSaving) commit("setRootValue", { key: "isSaving", value: true });

      await saveOrder(state.currentEntityId, state.data);

      if (isWithout1cSaving) {
        commit("setOrderData", { data: state.data, rootGetters });
        const iframeName = rootGetters["iframe/iframeName"];
        const parentIframeName = rootGetters["iframe/parentIframeName"];
        window.parent.postMessage(
          {
            event: `${iframeName}:emitMessage`,
            data: {
              iframeName: parentIframeName,
              eventName: "setOrderData",
              data: {
                orderData: state.data,
              },
            },
          },
          "*"
        );
      }
      commit("setRootValue", { key: "isCreated", value: true });
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка сохранения заказа`, { e });

      let text = "";
      if (e?.response) {
        text = `Ошибка сохранения заказа в БД. [${e.response.status}]: ${e.response.statusText}`;
      } else {
        text = e.message;
      }

      let widget = rootGetters["widgetRef"],
        targets = rootGetters["settings/showErrors"], //где отображать ошибки
        leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

      showError({
        widget,
        targets,
        leadId,
        header: `1С ${rootGetters["partnerName"]}`,
        text,
      });
    } finally {
      if (isWithout1cSaving) commit("setRootValue", { key: "isSaving", value: false });
    }
  },

  async realization({ state, dispatch, commit }) {
    commit("setRootValue", { key: "isSaving1c", value: true });
    const resp1c = await realization1c(state.data);
    if (resp1c.status == 200) {
      commit("setRealizationElement", resp1c.data);
      await dispatch("save");
    }
    commit("setRootValue", { key: "isSaving1c", value: false });
  },

  async save1c({ state, dispatch, commit, rootGetters }, showErrors = true) {
    const orderFieldsSettings = rootGetters["settings/orderFields"];
    const cardsFields = rootGetters["settings/cardsFields"];
    const defaultsSettings = rootGetters["settings/defaults"];
    const isOneStockOrder = rootGetters["settings/isOneStockOrder"];
    const amoCfsByTypes = rootGetters["amo/customFieldsByTypes"];
    const { amoEntity, amoContact, amoCompany } = state;

    commit("setRootValue", { key: "isSaving1c", value: true });

    try {
      await dispatch("save", false);
      let isAgreementsEnabled =
        rootGetters["features/getFeatures"].includes("agreements-1с-to-amo") &&
        rootGetters["settings/defaults"].isAgreementsEnabled;
      let isContractsEnabled =
        rootGetters["features/getFeatures"].includes("contracts-1с-to-amo") &&
        rootGetters["settings/defaults"].isContractsEnabled;
      let isContractRequired = isContractsEnabled && rootGetters["settings/defaults"].isContractRequired;
      let isStockRequired = rootGetters["settings/defaults"].isStockRequired;
      let isOrganizationRequired = rootGetters["settings/defaults"].isOrganizationRequired;
      let isAgreementReqiured = isAgreementsEnabled && rootGetters["settings/defaults"].isAgreementRequired;
      const tableFieldsSettings = rootGetters["settings/tableFieldsCustom"];
      if (showErrors) {
        orderUtil.checkAllowSaveOrderAndShowError(
          state.data,
          tableFieldsSettings,
          isOneStockOrder,
          isAgreementReqiured,
          isContractRequired,
          state.showContract,
          isStockRequired,
          isOrganizationRequired
        );

        if (defaultsSettings.isPaysCalendarEnabled) {
          orderUtil.checkPaysDetailAndShowError(state.data);
        }

        // проверка на обязательные поля
        const emptyFields = orderUtil.getEmptyRequiredFields({
          amoEntity,
          amoContact,
          amoCompany,
          orderFieldsSettings,
          cardsFields,
          amoCfsByTypes,
          order: state.data,
          amoAccountId: rootGetters["amoAccountId"],
        });

        if (emptyFields.length) {
          orderUtil.showEmptyFieldsModal(emptyFields);
          throw new Error("Необходимо заполнить обязательные поля в сущностях");
        }
      }

      if (state.update_order_number) commit("addParams", { update_order_number: 1 });

      const resp1c = await save1cOrder(state.data, state.params);
      if (resp1c.status != 200) throw "Ошибка сохранения заказа в 1С";

      if (resp1c.data?.error) {
        let errorText = resp1c.data?.number
          ? `<div>${resp1c.data.number}</div><br><div>${resp1c.data.error}</div>`
          : `<div>${resp1c.data.error}</div>`;
        showModal("Ошибка сохранения заказа в 1С", errorText, true);
        return;
      }

      commit("setOrderDataFrom1c", resp1c);
      commit("setOrderData", { data: state.data, rootGetters });
      dispatch("calcOrder");

      const iframeName = rootGetters["iframe/iframeName"];
      const parentIframeName = rootGetters["iframe/parentIframeName"];
      window.parent.postMessage(
        {
          event: `${iframeName}:emitMessage`,
          data: {
            iframeName: parentIframeName,
            eventName: "setOrderData",
            data: {
              orderData: state.data,
            },
          },
        },
        "*"
      );

      await dispatch("save", false);

      const use1cIframe = rootGetters["settings/iframe"].use;

      if (!state.disableModalClose && !use1cIframe && rootGetters["settings/actionCloseAferSaveOrder"]) {
        // настройка закрытия модалки после сохранения
        orderUtil.closeOrderModal(rootGetters["iframe/iframeName"]);
      }
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка сохранения заказа`, { e });

      let errorDataMsg = "";
      let errorText = "";

      if (e?.response) {
        errorDataMsg = e.response?.data?.message?.length ? " - " + e.response?.data?.message?.length : "";

        errorText = e.response
          ? e.response.data.body?.request?.error.length
            ? e.response.data.body?.request?.error
            : `[${e.response.status}]: ${e.response.statusText} ${errorDataMsg}`
          : e.message;
      } else {
        errorText = e.message;
      }

      let widget = rootGetters["widgetRef"],
        targets = rootGetters["settings/showErrors"], //где отображать ошибки
        leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

      showError({
        widget,
        targets,
        leadId,
        header: `1С ${rootGetters["partnerName"]}`,
        text: `Ошибка сохранения заказа в 1С. ${errorText}`,
      });
    } finally {
      commit("setRootValue", { key: "isSaving1c", value: false });
    }
  },

  setOrganizationData({ state, dispatch, commit, rootGetters }, { account1cId, organization1cId }) {
    const organization = rootGetters["organizations/getOrganizationById1c"](organization1cId);
    const orgName = organization?.name;
    const accName = rootGetters["organizations/getOrganizationAccountById1c"](account1cId)?.name;

    commit("setValue", { key: "organization_id", value: organization1cId || "" });
    commit("setValue", { key: "organization_name", value: orgName || "" });
    commit("setValue", { key: "organization_account_id", value: account1cId || "" });
    commit("setValue", { key: "organization_account_name", value: accName || "" });

    if (organization && organization.useNDS == 0) {
      commit("setValue", { key: "nds_type", value: 0 });
    } else {
      if (state.data.nds_type == 0) {
        // если стоит БЕЗ НДС, то ставим значение по умолчанию из настроек
        const defaultNdsType = rootGetters["settings/vatType"];

        if (defaultNdsType != 0) {
          commit("setValue", { key: "nds_type", value: defaultNdsType });
        }
      }
    }

    dispatch("onChangeNdsType");
  },

  onChangeNdsType({ state, getters, rootGetters, dispatch, commit }) {
    /*
    Триггер на смену типа НДС, при смене типа НДС меняет тип НДС у товаров и пересчитывает заказ
    */
    const productDataItems = rootGetters["products/items"];
    const defaultNdsVal = rootGetters["settings/defaults"].vat;

    if (!getters["isOrganizationUseNds"]) {
      commit("setProductsNds", { nds: "" }); // сбрасываем НДС у товаров
    } else {
      if (state.data.nds_type == 0) {
        // если стоит БЕЗ НДС
        commit("setProductsNds", { nds: "" }); // сбрасываем НДС у товаров
      } else {
        commit("setProductsNds", { productDataItems, defaultNdsVal });
      }
    }

    dispatch("calcOrder");
  },

  async calcAutoDiscount({ state, commit, dispatch, rootGetters }) {
    /* DEPRECATED!

    Функция расчета автоматической скидки. Функция не используется на данный момент

    Отправляем запрос в 1С получаем тело заказа с рассчитанными значениями
    */
    try {
      commit("setRootValue", { key: "isDiscountLoading", value: true });
      const response = await calcAutoDiscount(state.data);
      commit("setOrderData", { data: response.data, rootGetters });
      dispatch("save");
    } catch (e) {
      console.debug("Не удалось рассчитать автоматическую скидку");
    } finally {
      commit("setRootValue", { key: "isDiscountLoading", value: false });
    }
  },

  async saveEmpty1cOrder({ state, commit, dispatch, rootGetters }) {
    /**
     * Сохранение пустого заказа в 1С
     *
     * Необходимо для открытия окна iFrame в 1С
     *
     * Если в настройках по умолчанию не выбрано организации присвоим ей nullId для корректного создания заказа
     *
     */

    try {
      await dispatch("updateOrderByEntityData");

      const currentEntityId = rootGetters["order/entity"].entityId;

      const nullId = "00000000-0000-0000-0000-000000000000";

      state.data.request_source = "iframe";
      if (!state.data.organization_id) state.data.organization_id = nullId;
      if (!state.data.organization_account_id) state.data.organization_account_id = nullId;

      const isOrgUseNds = !!rootGetters["organizations/getOrganizationById1c"](state.data.organization_id)?.useNDS;
      const defaultNds = rootGetters["settings/defaults"].vatType;

      state.data.nds_type = isOrgUseNds ? defaultNds : "0";

      const resp1c = await save1cOrder(state.data, state.params);
      if (resp1c.status != 200) throw "Ошибка сохранения заказа в 1С";

      state.isCreated = true;
      commit("setOrderDataFrom1c", resp1c);
      commit("setOrderData", { data: state.data, rootGetters });
      dispatch("calcOrder");

      const iframeName = rootGetters["iframe/iframeName"];
      const parentIframeName = rootGetters["iframe/parentIframeName"];

      window.parent.postMessage(
        {
          event: `${iframeName}:emitMessage`,
          data: {
            iframeName: parentIframeName,
            eventName: "setOrderData",
            data: {
              orderData: state.data,
            },
          },
        },
        "*"
      );

      await saveOrder(currentEntityId, state.data);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка сохранения заказа`, { e });

      const errorDataMsg = e.response?.data?.message?.length ? " - " + e.response?.data?.message?.length : "";

      const errorText = e.response
        ? e.response.data.body?.request?.error.length
          ? e.response.data.body?.request?.error
          : `[${e.response.status}]: ${e.response.statusText} ${errorDataMsg}`
        : e.message;

      let widget = rootGetters["widgetRef"],
        targets = rootGetters["settings/showErrors"], //где отображать ошибки
        leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

      showError({
        widget,
        targets,
        leadId,
        header: `1С ${rootGetters["partnerName"]}`,
        text: `Ошибка создания заказа: ${errorText}`,
      });
    }
  },

  // действие обновление данных заказа, когда открывается модалка заказа
  async updateOrderByEntityData({ state, commit, dispatch, rootGetters }) {
    /*
    Обновление данных заказа, когда открывается модалка заказа
    */
    commit("setRootValue", { key: "errorOpenOrder", value: "" });

    const order = deepCloneByJson(state.data);
    const settings = rootGetters["settings/getDocflowSettings"];
    const accountData = rootGetters["accountData"];

    const currentUser = rootGetters["amoUser"];

    const isCompanyInitial = order.is_company;

    let entity;

    const { entityId, entityType } = rootGetters["order/entity"];

    if (entityType == 2) {
      /*
      Если это сделка:
      1. Получим сделку по API
      2. Получим воронку и статус, заполним поля заказа
      */
      entity = await amoApi.getEntityById({ entitiesType: "leads", id: entityId });

      if (!entity) {
        commit("setRootValue", {
          key: "errorOpenOrder",
          value: `Сделка ${entityId} не найдена. Проверьте сущестование сделки`,
        });
        return;
      }

      commit("setRootValue", { key: "amoEntity", value: entity });

      const leadPipeline = rootGetters["amo/pipelineById"](entity.pipeline_id);
      const leadStatus = rootGetters["amo/statusById"](entity.status_id);

      order["lead_id"] = `${entity.id}`;
      order["lead_name"] = entity.name;
      order["pipeline_id"] = leadPipeline?.id ? `${leadPipeline.id}` : "";
      order["pipeline_name"] = leadPipeline?.name || "";
      order["stage_id"] = leadStatus?.id ? `${leadStatus.id}` : "";
      order["stage_name"] = leadStatus?.name || "";
      order["lead_link"] = generateEntityLink(entityId, entityType, accountData);

      if (settings.defaults?.updateDescription) {
        order["order_description"] = getCfValue(entity, settings.extraCfs.descriptionCfId);
      }
    }

    if (entityType == 12) {
      /*
      Если это покупатель
      1. Получим покупателя по API
      2. Заполним поля заказа
      */
      entity = await amoApi.getEntityById({ entitiesType: "customers", id: entityId });

      if (!entity) {
        commit("setRootValue", {
          key: "errorOpenOrder",
          value: `Покупатель ${entityId} не найден. Проверьте сущестование покупателя`,
        });
        return;
      }

      dispatch("amo/setCurrentEntityData", entity, { root: true });
      commit("setRootValue", { key: "amoEntity", value: entity });

      order["customer_id"] = entity.id;
      order["customer_name"] = entity.name;
      order["customer_link"] = generateEntityLink(entityId, entityType, accountData);
    }

    const hasLinkedCompany = entityHasLinkedCompany(entity);
    const hasLinkedContact = entityHasLinkedContact(entity);

    if (!hasLinkedCompany && !hasLinkedContact) {
      let errorMsg = "";
      if (entityType == 2) {
        errorMsg = rootGetters["localSettings/errorOrderLinkContact"];
      } else if (entityType == 12) {
        errorMsg = rootGetters["localSettings/errorOrderCustomerContact"];
      }
      commit("setRootValue", { key: "errorOpenOrder", value: errorMsg });
      return;
    }

    if (!hasLinkedCompany) orderUtil.clearEntityFields("company_", order);
    if (!hasLinkedContact) orderUtil.clearEntityFields("contact_", order);

    const amoCfsByIds = rootGetters["amo/customFieldsByIds"];
    order["is_company"] = typeof entity?._embedded?.companies?.[0]?.id != "undefined" ? 1 : 0;
    const users = rootGetters["accountData"].amoUsers;
    const groups = Object.values(rootGetters["accountData"].amoUserGroups);
    orderUtil.setManagerData(
      order,
      users,
      groups,
      entity.responsible_user_id,
      rootGetters["amoUserId"],
      rootGetters["settings/defaults"]?.managerType
    );

    if (entityType == 2) {
      orderUtil.setEntityFields(
        rootGetters["accountData"].amoTimezone,
        settings.orderFields,
        amoCfsByIds,
        "lead",
        entity,
        order
      );
    } else if (entityType == 12) {
      orderUtil.setEntityFields(
        rootGetters["accountData"].amoTimezone,
        settings.orderFields,
        amoCfsByIds,
        "customer",
        entity,
        order
      );
    }

    let contacts = [];

    if (hasLinkedCompany) {
      const company = await amoApi.getEntityById({ entitiesType: "companies", id: entity._embedded.companies[0].id });

      if (company) {
        commit("setRootValue", { key: "amoCompany", value: company });

        const isCompanyContactsRequired = settings.defaults.linkedContactsType.includes(0);

        if (rootGetters["features/getFeatures"].includes("contacts-amo-to-1с") && isCompanyContactsRequired) {
          const ids = company._embedded.contacts.map((c) => c.id).slice(0, 10);

          let data = [];

          if (ids?.length) {
            data = await orderUtil.getContactsData({ ids, rootGetters });
          }
          contacts = [...contacts, ...data];
        }

        order["company_id"] = company["id"].toString();
        order["company_short_name"] = company["name"];
        if (rootGetters["features/getFeatures"].includes("responsible-user-amo-to-1c")) {
          const users = rootGetters["accountData"].amoUsers;
          const groups = Object.values(rootGetters["accountData"].amoUserGroups);
          orderUtil.setCompanyManagerData(order, users, groups, company.responsible_user_id);
        }
        orderUtil.setEntityFields(
          rootGetters["accountData"].amoTimezone,
          settings.orderFields,
          amoCfsByIds,
          "company",
          company,
          order
        );
      } else {
        order["is_company"] = 0;
        commit("addErrorLinkedEntity", { type: "company", id: entity?._embedded?.companies?.[0]?.id });
      }
    }

    let mainContactId = 0;

    if (hasLinkedContact) {
      mainContactId = entity._embedded.contacts.find((c) => c.is_main).id;
      const contact = await amoApi.getEntityById({ entitiesType: "contacts", id: mainContactId });

      if (contact) {
        commit("setRootValue", { key: "amoContact", value: contact });

        order["contact_id"] = contact["id"].toString();
        order["contact_short_name"] = contact["name"];
        if (rootGetters["features/getFeatures"].includes("responsible-user-amo-to-1c")) {
          const users = rootGetters["accountData"].amoUsers;
          const groups = Object.values(rootGetters["accountData"].amoUserGroups);
          orderUtil.setContactManagerData(order, users, groups, contact.responsible_user_id);
        }
        orderUtil.setEntityFields(
          rootGetters["accountData"].amoTimezone,
          settings.orderFields,
          amoCfsByIds,
          "contact",
          contact,
          order
        );
        orderUtil.setContactEmailsAndPhones(order, contact, rootGetters);
      } else {
        commit("addErrorLinkedEntity", { type: "contact", id: mainContactId });
      }

      const isLeadContactsRequired = settings.defaults.linkedContactsType.includes(1);

      if (rootGetters["features/getFeatures"].includes("contacts-amo-to-1с") && isLeadContactsRequired) {
        const ids = entity._embedded.contacts.map((c) => c.id).slice(0, 10);
        let data = [];
        if (ids?.length) {
          data = await orderUtil.getContactsData({ ids, rootGetters });
        }
        contacts = [...contacts, ...data];
      }
    }

    if (rootGetters["amoAccountId"] == 17536120 && rootGetters["order/isCreated"]) {
      order["is_company"] = isCompanyInitial;
    }

    // Фильтр дубликатов
    order.contacts = [];

    if (hasLinkedCompany) contacts = contacts.filter((c) => c.contact_id != mainContactId);

    if (settings.defaults.checkEmptyContactsData) {
      contacts = contacts.filter((c) => c.contact_emails.length || c.contact_phones.length);
    }

    contacts.forEach((c) => {
      if (!order.contacts.find((nc) => nc.contact_id == c.contact_id)) {
        order.contacts.push({ ...c, is_main: c.contact_id == mainContactId });
      }
    });

    orderUtil.setCurrentManagerData(order, currentUser);

    state.data = order;

    const appendCfToTotalPrice = orderUtil.getAdditionalCfSum({
      accountData,
      entityType,
      defaultsSettings: settings.defaults,
      entity,
    });
    Vue.set(state.additionalData, "appendCfToLeadPrice", appendCfToTotalPrice);
    await dispatch("partners/loadContragentsByPartnerId", rootGetters, { root: true });
    dispatch("calcOrder");
    commit("setOpened", {}, { root: true });
  },

  setDefaultContract({ state, dispatch, rootGetters }) {
    const defaults = rootGetters["settings/defaults"];
    if (!state.data.order_id && defaults.setIndividualContract) {
      const individualContracts = rootGetters["contracts/individualContracts"];
      if (individualContracts.length == 1) {
        dispatch("setValue", { key: "contract_id", value: individualContracts[0].value });
      }
    }
  },

  setStock({ state, commit, rootGetters }, stockId) {
    const stockWorktype = rootGetters["settings/stockWorktype"];

    if (stockWorktype == 0) {
      const stocks = rootGetters["stocks/itemsById"];
      const productData = rootGetters["products/getById"];
      const stock = stocks[stockId];
      commit("setStock", stock);

      state.data.products.forEach((product) => {
        let stockInList = productData(product.id).stocks.find((v) => v.id == stock?.id);
        if (stockInList) commit("setProductStock", { product, stock });
        else commit("setProductStock", { product, stock: null });
      });
    }
  },

  setProductStock({ commit, rootGetters }, { product, stockId }) {
    const stocks = rootGetters["stocks/itemsById"];
    const stock = stocks[stockId];
    commit("setProductStock", { product, stock });
  },

  setProductTotalPrice({ commit, dispatch }, payload) {
    commit("setProductTotalPrice", payload);
    dispatch("calcProcuct", payload.product);
  },

  calcProcuct({ commit }, product) {
    commit("calcProcuct", { product });
    commit("calcOrderTotal");
  },

  calcOrder({ getters, commit }) {
    /**
     * Здесь был setDefaultVatForProducts, убрали т.к. ошибки возникают
     */
    getters.products.forEach((product) => commit("calcProcuct", { product }));
    commit("calcOrderTotal");
  },

  deleteProduct({ commit }, payload) {
    commit("deleteProduct", payload);
    commit("calcOrderTotal");
  },

  addProduct({ state, commit, getters, dispatch, rootGetters }, payload) {
    const defaultsSettings = rootGetters["settings/defaults"];
    const tableFieldCustomWorkers = rootGetters["settings/tableFieldCustomWorkers"];
    const isOrganizationUseNds = getters["isOrganizationUseNds"];

    let priceTypeId;

    if (defaultsSettings.isAgreementsEnabled && getters.agreementId) {
      priceTypeId = rootGetters["agreements/getPriceTypeId"](getters.agreementId);
    }

    priceTypeId = priceTypeId ? priceTypeId : defaultsSettings.priceTypeId;
    priceTypeId = priceTypeId ? priceTypeId : payload.priceTypeId;

    commit("addProduct", {
      tableFieldCustomWorkers,
      defaultsSettings,
      ...payload,
      isOrganizationUseNds,
      priceTypeId,
    });

    const product = state.data.products[state.data.products.length - 1];
    dispatch("calcProcuct", product);

    rootGetters["extension/onProductAdded"]?.call(null, {
      product,
      productData: payload.productData,
    });
  },

  recalcPrices({ commit, getters, dispatch, rootGetters }) {
    let agreementId = getters.agreementId;
    if (getters.isNdsUsed) {
      let nds = rootGetters["agreements/getTypeNds"](agreementId);
      commit("setValue", { key: "nds_type", value: nds });
      dispatch("onChangeNdsType");
    }
    let priceTypeId = rootGetters["agreements/getPriceTypeId"](agreementId);
    commit("recalcPrices", { priceTypeId, rootGetters });
    dispatch("calcOrder");
  },

  processRealizationElement: ({ commit }, payload) => commit("setRealizationElement", payload),
  dropState: ({ commit }) => commit("dropState"),
  resetAll: ({ commit }) => commit("resetAll"),
  clearErrorEntitiesList: ({ commit }) => commit("clearErrorEntitiesList"),
  setValue: ({ commit }, payload) => commit("setValue", payload),
  setRootValue: ({ commit }, payload) => commit("setRootValue", payload),
  setProductFieldValue: ({ commit }, payload) => commit("setProductFieldValue", payload),
  updateDate: ({ commit }, value) => commit("updateDate", value),
  setOrderCf: ({ commit, rootGetters }, payload) => commit("setOrderCf", { payload, rootGetters }),
  setOrderDateCf({ commit, rootGetters }, payload) {
    payload.value = moment(payload.value).format("DD.MM.YYYY 0:00:00");
    commit("setOrderCf", { payload, rootGetters });
  },
  setCurrentEntity: ({ commit }, payload) => commit("setCurrentEntity", payload),

  changeCustomFieldProduct: ({ commit }, payload) => commit("changeCustomFieldProduct", payload),

  ...massUseActions,
  ...paysActions,
  ...orderCopyActions,
  ...orderUpdateActions,
};
