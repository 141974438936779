<template>
  <div>
    <div :class="$style.parameters">
      <div :class="$style.wrapper">
        <div :class="$style.row">
          <div :class="[$style.title, defaults.isOrganizationRequired ? $style.required : '']">Юр. лицо</div>
          <div :class="[$style.control, $style.w350, $style.space]" code="organizations">
            <GnzsDropdown
              :value="organizationAccount1cId"
              :is-grouped="true"
              :items="organizations"
              field-name="organizations"
              @input="onOrganizationAccountChangeHandler"
              :use-empty-item="!defaults.isOrganizationRequired"
              :disabled="!!isOrgSelectionDisabled" />
          </div>
        </div>
        <div v-if="stockWorktype == 0 && !features.includes('no-choice-of-storage')" :class="$style.row">
          <div :class="[$style.title, isStockRequired ? $style.required : '']">Склад</div>
          <div :class="[$style.control, $style.w350, $style.space]" code="stocks">
            <GnzsDropdown v-model="stockId" :items="stocks" @input="onStockChange" emptyItemTitle="Несколько складов" />
          </div>
        </div>

        <div v-if="amoAccountId == 17536120" :class="$style.row">
          <div :class="$style.title">Счёт на Юр. лицо</div>
          <div><GnzsSwitcher :value="isCompany" :disabled="hasOneContragent" @input="onContragentTypeChange" /></div>
        </div>

        <div hidden id="tooltip" :class="$style.tooltip"></div>

        <div :class="$style.row" v-for="(param, index) in settingsOrderCFs" :key="index">
          <div
            @mouseleave="hideToolTip"
            @mouseover="showToolTip"
            :class="[$style.title, param.required ? $style.required : '']">
            {{ param.title }}
          </div>
          <CustomField :param="param" />
        </div>

        <div v-if="defaults.isAgreementsEnabled && features.includes('agreements-1с-to-amo')" :class="$style.row">
          <div :class="[$style.title, $style.required]">Соглашение</div>
          <div :class="[$style.control, $style.w350]" code="agreements">
            <GnzsDropdown
              :value="agreementId"
              :items="currentAgreements"
              :is-grouped="true"
              :is-searchable="currentAgreementsLength > 10"
              :use-empty-item="false"
              @input="onAgreementChangeHandler" />
            <GnzsButton v-if="isRecalcEnabled" :class="$style.button" @click="recalcPrices"
              >Пересчитать цены</GnzsButton
            >
          </div>
        </div>

        <div
          v-if="defaults.isContractsEnabled && features.includes('contracts-1с-to-amo') && showContract"
          :class="$style.row">
          <div :class="[$style.title, $style.contract, defaults.isContractRequired ? $style.required : '']">
            Договор
          </div>
          <div :class="[$style.control, contractViolation ? $style.contract_drop : '']" code="contracts">
            <div v-if="contractViolation" @click="showContractInfo" :class="$style.contractViolation">
              <svg>
                <use xlink:href="#gnsz-1c-icon__info-error"></use>
              </svg>
            </div>
            <GnzsDropdown
              :class="[contractViolation ? $style.violation : '']"
              v-model="contractId"
              :items="contracts"
              :is-grouped="true"
              :is-searchable="contractsItems.length > 10"
              @input="onContractChangeHandler" />
            <GnzsButton v-if="defaults.isContractCreationEnabled" type="append" @click="addContract"
              >Создать</GnzsButton
            >
          </div>
        </div>
      </div>

      <Partners
        v-if="defaults.isPartnersEnabled && features.includes('partners-1c-to-amo')"
        @change="onContragentChangeHandler" />
      <div v-else></div>

      <!-- КОНЕЦ БЛОКа -->

      <div>
        <div :class="[$style.control__end, $style.space, $style.pb11]">
          <GnzsButton
            v-if="defaults.isEvotorButtonEnabled && features.includes('send-to-evotor')"
            type="primary"
            :disabled="!hasOrder1c"
            :loading="isSavingEvotor"
            @click="sendToEvotor"
            >Отправить в Эвотор</GnzsButton
          >
          <GnzsDropdown
            v-model="ndsType"
            :items="vatTypes"
            :use-empty-item="false"
            :disabled="!isOrganizationUseNds"
            @input="onNdsChange" />
        </div>
      </div>
    </div>
    <div v-if="tariffFeatures.includes('additional-buttons')" :class="$style.extraActions">
      <div v-for="(button, index) in extraActions" :key="index">
        <GnzsButton
          :loading="isRunningAction(button.code)"
          :class="[$style.extraActions__action, { [$style.loadingCentered]: isRunningAction(button.code) }]"
          @click="runExtraAction(button.code)"
          >{{ button.name }}</GnzsButton
        >
      </div>
    </div>
    <div>
      <FsLightbox :toggler="showGallery" :sources="gallery" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";
  import $ from "jquery";
  import FsLightbox from "fslightbox-vue";

  import openAddContractModal from "./../Modals/AddContractModal";
  import openContractInfoModal from "./../Modals/ContractInfoModal";
  import CustomField from "./CustomField";
  import Partners from "./Partners";
  import isJson from "@/utilities/isJson";

  // функция для создания методов вычисляемого поля, которое может работать с директивой v-model
  const mapFieldModel = function (key) {
    return {
      get() {
        return this.$store.getters[`order/getValue`](key);
      },
      set(value) {
        this.$store.dispatch("order/setValue", { key, value });
      },
    };
  };

  // функция для создания методов вычисляемого поля, которое может работать с директивой v-model
  const mapFieldModelGallery = function () {
    return {
      get() {
        return this.$store.getters[`products/showGallery`];
      },
      set(value) {
        this.$store.dispatch("products/toggleGallery", value);
      },
    };
  };

  export default {
    data() {
      return {
        timeoutID: null,
        filter: 0,
        show: false,
      };
    },
    computed: {
      ...mapGetters({
        organizations: "organizations/orderItems",
        organizationAccount1cId: "order/organizationAccount1cId",
        isOrganizationUseNds: "order/isOrganizationUseNds",
        orderDate: "order/date",
        isSavingEvotor: "order/isSavingEvotor",
        isDiscountLoading: "order/isDiscountLoading",
        stocks: "stocks/dropdownItems",
        vatTypes: "localSettings/vatTypes",
        extraActions: "settings/extraActions",
        stockWorktype: "settings/stockWorktype",
        settingsOrderCFs: "settings/orderCFs",
        features: "features/getFeatures",
        contracts: "contracts/dropdownItems",
        contractsItems: "contracts/items",
        individualContracts: "contracts/individualContracts",
        hasOrder1c: "order/hasOrder1c",
        defaults: "settings/defaults",
        partners: "settings/partners",
        isRecalcEnabled: "order/isCalcRequired",
        agreementId: "order/agreementId",
        isRunningAction: "order/isRunningAction",
        getOrganizationId: "agreements/getOrganizationId",
        getOrganizationAccountId: "organizations/getOrganizationById1c",
        getOrganizationAccountById1c: "organizations/getOrganizationAccountById1c",
        tariffFeatures: "features/getFeatures",
        agreements: "agreements/items",
        dropdownItems: "agreements/dropdownItems",
        commonAgreements: "agreements/common",
        organizationsDefaults: "settings/organizationsDefaults",
        contractById: "contracts/contractById",
        useContract: "agreements/useContract",
        getRootValue: "order/getRootValue",
        ownerAgreements: "agreements/byOwnerId",
        partnerAgreements: "agreements/byPartnerId",
        defaultCurrency: "settings/currency",
        currencies: "localSettings/currencies",
        gallery: "products/gallery",
        hasContact: "order/hasContact",
        hasCompany: "order/hasCompany",
        data: "order/orderData",
        amoAccountId: "amoAccountId",
      }),
      stockId: { ...mapFieldModel.call(this, "stock_id") },
      ndsType: { ...mapFieldModel.call(this, "nds_type") },
      contractId: { ...mapFieldModel.call(this, "contract_id") },
      partnerId: { ...mapFieldModel.call(this, "partner_id") },
      showGallery: { ...mapFieldModelGallery.call(this) },
      isCompany: { ...mapFieldModel.call(this, "is_company") },
      showContract: {
        get() {
          return this.getRootValue("showContract");
        },
        set(value) {
          this.setRootValue({ key: "showContract", value });
        },
      },
      isOrgSelectionDisabled() {
        if (
          this.organizations?.[0]?.items?.length == 1 &&
          this.organizationAccount1cId != this.organizations?.[0]?.items?.[0].id1c
        ) {
          return false;
        }
        return (
          this.defaults.disableOrganizationChange ||
          (this.organizations?.length == 1 &&
            this.organizations?.[0]?.items?.length == 1 &&
            this.organizationAccount1cId != "")
        );
      },
      isStockRequired() {
        return this.defaults.isStockRequired;
      },
      contractViolation() {
        return this.contractById(this.contractId)?.data?.violation;
      },
      currentAgreementsLength() {
        return this.currentAgreements.reduce((acc, item) => acc + item.items.length, 0);
      },
      currentAgreements() {
        // TODO вынести в store agreements
        // 0 - все, 1 - у контрагента, 2 - у партнера, 3 - общие
        let filterVal = this.filter;
        if (!this.defaults.isPartnersEnabled) {
          filterVal = 0;
        }

        const filterPid = isJson(this.partnerId) ? JSON.parse(this.partnerId).value : this.partnerId;

        switch (filterVal) {
          case 0:
            return this.dropdownItems(this.agreements);
          case 1:
            return this.dropdownItems(this.ownerAgreements(this.contragentId));
          case 2:
            return this.dropdownItems(this.partnerAgreements(filterPid));
          case 3:
            return this.dropdownItems(this.commonAgreements(filterPid, this.contragentId));
          default:
            return this.dropdownItems(this.agreements);
        }
      },
      hasOneContragent() {
        if (this.hasContact && this.hasCompany) {
          return false;
        }

        return true;
      },
    },
    methods: {
      onContragentTypeChange(value) {
        if (value) {
          this.isCompany = 1;
        } else {
          this.isCompany = 0;
        }
      },
      open() {
        this.show = !this.show;
      },
      ...mapActions({
        setValue: "order/setValue",
        setRootValue: "order/setRootValue",
        sendToEvotor: "order/sendToEvotor",
        setStock: "order/setStock",
        setContractParams: "order/setContractParams",
        getContractData: "contracts/getContractData",
        setOwnerAgreement: "order/setOwnerAgreement",
        runExtraAction: "order/runExtraAction",
      }),
      onContractChangeHandler(id) {
        let contract = this.contractById(id);
        const defaultCurrencyCode = this.currencies.find((c) => c.name == this.defaultCurrency).code;
        const code = contract?.currency_code ? contract.currency_code : defaultCurrencyCode;
        this.setValue({ key: "currency_code", value: code });

        this.setContractParams();
        this.getContractData();
      },
      onOrganizationAccountChangeHandler(account1cId, organization1cId) {
        this.$store.dispatch("order/setOrganizationData", { account1cId, organization1cId });
        this.setValue({ key: "agreement_id", value: "" });
        this.setValue({ key: "contract_id", value: "" });
        let account = this.getOrganizationAccountById1c(account1cId);
        const defaultCurrencyCode = this.currencies.find((c) => c.name == this.defaultCurrency).code;
        const code = account?.currencyCode ? account.currencyCode : defaultCurrencyCode;
        this.setValue({ key: "currency_code", value: code });
        if (this.features.includes("agreements-1с-to-amo") && this.defaults.isAgreementsEnabled) {
          this.$store.dispatch("agreements/load");
        }
        if (this.features.includes("contracts-1с-to-amo") && this.defaults.isContractsEnabled) {
          this.$store.dispatch("contracts/load");
        }
        let values = this.organizationsDefaults.find((val) => val.orgId == organization1cId);

        if (values) {
          this.setValue({ key: "stock_id", value: values.stockId });
          if (this.defaults.ownerAgreementFirst) {
            this.setOwnerAgreement(false);
            if (!this.agreementId) this.setValue({ key: "agreement_id", value: values.agreementId });
          } else {
            this.setValue({ key: "agreement_id", value: values.agreementId });
          }
        } else {
          this.setOwnerAgreement(false);
        }
      },
      onAgreementChangeHandler(id) {
        this.setValue({ key: "agreement_id", value: id });
        if (id && this.defaults.isAgreementsEnabled) {
          if (this.isOrganizationUseNds) {
            const agreement = this.agreements.find((a) => a.id == id);
            this.setValue({ key: "nds_type", value: agreement.nds_included ? 1 : 2 });
          }
          this.showContract = !!this.useContract(id);
          if (this.showContract && this.defaults.setIndividualContract) {
            if (this.individualContracts.length == 1) {
              const value = this.individualContracts.pop().value;
              this.setValue({ key: "contract_id", value });
              this.onContractChangeHandler(value);
            }
          } else {
            this.setValue({ key: "contract_id", value: "" });
            this.onContractChangeHandler();
          }
        }
        if (!this.organizationAccount1cId) {
          let groupValue = this.getOrganizationId(this.agreementId);
          let value = this.getOrganizationAccountId(groupValue).accounts[0].id1C;
          this.$store.dispatch("order/setOrganizationData", { account1cId: value, organization1cId: groupValue });
        }
      },
      onContragentChangeHandler(id) {
        this.setValue({ key: "contragent_id", value: id });
        this.setValue({ key: "agreement_id", value: "" });
        this.filter = 0;
        let ownerAgreements = this.ownerAgreements(this.contragentId);
        let partnerAgreements = this.partnerAgreements(this.partnerId);

        if (!ownerAgreements.length && !partnerAgreements.length) {
          this.filter = 0;
        } else {
          if (ownerAgreements.length && partnerAgreements.length) {
            this.filter = 3;
          } else if (!ownerAgreements.length) {
            this.filter = 2;
            if (partnerAgreements.length == 1) {
              this.setValue({ key: "agreement_id", value: partnerAgreements[0].id });
            }
          } else if (!partnerAgreements.length) {
            this.filter = 1;
            if (ownerAgreements.length == 1) {
              this.setValue({ key: "agreement_id", value: ownerAgreements[0].id });
            }
          }
        }
      },
      onNdsChange() {
        this.$store.dispatch("order/onChangeNdsType");
        this.$store.dispatch("realizations/onChangeNdsType");
      },
      onStockChange(stockId) {
        this.$store.dispatch("order/setStock", stockId);
      },
      updateOrderDate() {
        this.$store.dispatch("order/updateDate");
      },
      addContract() {
        openAddContractModal();
      },
      showContractInfo() {
        openContractInfoModal();
      },
      recalcPrices() {
        this.$store.dispatch("order/recalcPrices");
      },
      hideToolTip(event) {
        clearTimeout(this.timeoutID);
        $(event.target).children().remove();
      },
      showToolTip(event) {
        let element = event.target;

        if (element.innerHTML.length < 18) return;
        if ($(element).attr("id") == "tooltip") return;
        if ($(element).children().length) return;

        this.timeoutID = setTimeout(() => {
          let tooltip = $("#tooltip").clone();
          tooltip.attr("hidden", false);
          tooltip.html(event.target.innerHTML);
          $(element).append(tooltip);
        }, 1000);
      },
    },
    created() {
      if (this.contractId) {
        this.onContractChangeHandler();
      }
      if (this.agreementId) {
        this.onAgreementChangeHandler(this.agreementId);
      }
    },
    components: {
      GnzsDropdown,
      GnzsSwitcher,
      GnzsButton,
      CustomField,
      Partners,
      FsLightbox,
    },
  };
</script>

<style lang="scss" module>
  @import "./Parameters.scss";
</style>
