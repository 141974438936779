<template>
  <GnzsSection header="Копирование заказа">
    <template v-slot:description>Функционал позволяет задать правила копирования заказа/сделки</template>

    <div
      :class="[$style.wrapper, 'gnzs-piplines-id-slot']"
      v-for="(pipeline, index) in copySettings.pipelines"
      :key="index">
      <div
        :id="`slot_{${pipeline.id}}`"
        :class="[{ [$style.pointer]: activeSlot != pipeline.id }, 'gnzs-piplines-id-wrapper']"
        @click="setActiveSlot(pipeline.id)">
        <div :class="$style.pipelineName">
          <div>{{ getName(pipeline.id) }}</div>
          <GnzsButton v-if="activeSlot == pipeline.id" type="remove" @click="removePipeline(index)" />
          <svg v-else @click="setActiveSlot(pipeline.id)" :class="['gnzs-iframe-user-slot-dots', $style.dots]">
            <use xlink:href="#gnzs-1c-icon__dots"></use>
          </svg>
        </div>
        <div v-if="activeSlot == pipeline.id" :class="[w.table, $style.options]">
          <div :class="w.tableRow">
            <div :class="$style.mw220">Шаблон имени сделки</div>
            <GnzsInput :class="$style.mw220" :placeholder="placeholder" v-model="pipeline.name" />
          </div>
          <div :class="w.tableRow">
            <div :class="$style.mw220">Этап создания</div>
            <GnzsDropdown
              :class="w.w200"
              :use-empty-item="true"
              emptyItemTitle="Авто"
              v-model="pipeline.status_id"
              :items="amoStatuses" />
          </div>
          <div :class="w.tableRow">
            <div :class="$style.mw220">Ответственный</div>
            <GnzsDropdown
              :class="w.w200"
              v-model="pipeline.responsible_id"
              :use-empty-item="true"
              :isSearchable="true"
              emptyItemTitle="Текущий ответственный"
              :items="users" />
          </div>
          <div :class="w.tableRow">
            <div :class="$style.mw220">Исключить копирование полей</div>
            <GnzsDropdownCheckboxes
              :class="w.w200"
              v-model="pipeline.except_fields"
              :isSearchable="true"
              :items="getAmoEntityCfs('lead')" />
          </div>
          <div :class="[w.tableRow]">
            <div :class="[$style.exCopyTags]">Исключить копирование тегов</div>
            <div :class="[w.cellInner, w.checkboxRequireContainer]">
              <GnzsCheckbox v-model="pipeline.except_tags" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.appendButtonContainer">
      <GnzsDropdown
        :class="$style.pipelineDropdown"
        :append="true"
        :items="uncheckedPipelines"
        :useEmptyItem="false"
        :isSearchable="true"
        :emptyItemTitle="'Добавить воронку'"
        @input="appendPipeline($event)"></GnzsDropdown>
    </div>
  </GnzsSection>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsDropdownCheckboxes from "@/gnzs-controls/DropdownCheckboxes";
  import GnzsInput from "@/gnzs-controls/Input";
  import $ from "jquery";

  export default {
    data() {
      return {
        activeSlot: 0,
        placeholder: "Копия: {{ lead.name }}",
      };
    },
    computed: {
      ...mapGetters({
        users: "amo/users",
        pipelines: "amo/pipelines",
        amoStatuses: "amo/statusesDropdownItems",
        statuses: "amo/statuses",
        copySettings: "settings/copySettings",
        helpers: "localSettings/helpers",
        tariffFeatures: "features/getFeatures",
        disabledSectionText: "localSettings/disabledSectionText",
        customFieldsByTypes: "amo/customFieldsByTypes",
      }),
      activePipelines() {
        return this.copySettings.pipelines.map((p) => p.id);
      },
      checkedPipelines() {
        return this.pipelines.filter((p) => {
          return this.activePipelines.includes(p.value);
        });
      },
      uncheckedPipelines() {
        return this.pipelines.filter((p) => {
          return !this.activePipelines.includes(p.value);
        });
      },
    },
    methods: {
      ...mapActions({
        appendPipeline: "settings/appendPipilineCopyOptions",
        removePipeline: "settings/removePipilineCopyOptions",
      }),
      getName(id) {
        return this.pipelines.find((p) => p.value == id).title;
      },
      getAmoEntityCfs(entity, types = ["all"]) {
        return types.reduce((cfs, t) => [...cfs, ...this.customFieldsByTypes[entity][t]], []);
      },
      setActiveSlot(id) {
        this.activeSlot = id;
        $(`#slot_${id}`).focus();
      },
    },
    mounted() {
      $("body").click((e) => {
        if (
          $(e.target).hasClass("gnzs-piplines-id-slot") ||
          $(e.target).hasClass("gnzs-piplines-id-slot-wrapper") ||
          $(e.target).hasClass("gnzs-piplines-id-slot-dots")
        ) {
          return;
        }
        if (!$(e.target).closest(".gnzs-piplines-id-slot").length) {
          this.activeSlot = 0;
        }
      });
    },
    components: {
      GnzsSection,
      GnzsButton,
      GnzsCheckbox,
      GnzsDropdown,
      GnzsDropdownCheckboxes,
      GnzsInput,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  @import "./style.scss";
</style>
