<template>
  <div>
    <ModeSwitcher v-model="mode" v-if="isAcquiringDocsEnabled" />
    <template v-if="mode == 0">
      <OrderInfo />
      <CreateDocument v-if="isOrderCreated" />
      <Documents />
    </template>
    <Invoices v-else />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Invoices from "@/components/Tab/Invoices/Invoices";
  import OrderInfo from "@/components/Tab/OrderInfo/OrderInfo";
  import CreateDocument from "@/components/Tab/CreateDocument/CreateDocument";
  import Documents from "@/components/Tab/Documents/Documents";
  import ModeSwitcher from "@/components/Tab/TabWrapper/ModeSwitcher";

  export default {
    data() {
      return {
        mode: 0,
      };
    },
    computed: {
      ...mapGetters({
        isOrderCreated: "order/isCreated",
        amoAccountId: "amoAccountId",
      }),
      isAcquiringDocsEnabled() {
        return [17536120, 30913102].includes(this.amoAccountId);
      },
    },
    components: {
      ModeSwitcher,
      OrderInfo,
      CreateDocument,
      Documents,
      Invoices,
    },
  };
</script>

<style></style>
