<template>
  <div :class="$style.wrapper">
    <div :class="[$style.leftSide, { [$style.active]: value == 0 }]" @click="setOrder">
      <div>
        <svg><use xlink:href="#gnzs-1c-icon__order"></use></svg>
      </div>
      <div v-if="value == 0">Заказ</div>
    </div>
    <div :class="[$style.rightSide, { [$style.active]: value == 1 }]" @click="setInvoices">
      <div>
        <svg><use xlink:href="#gnzs-1c-icon__invoice"></use></svg>
      </div>
      <div v-if="value == 1">Счета</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      setOrder() {
        this.$emit("input", 0);
      },
      setInvoices() {
        this.$emit("input", 1);
      },
    },
    components: {},
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>
