<template>
  <div :class="$style.controls">
    <div :class="$style.controlsContainer">
      <div :class="$style.controlsWrapper">
        <div :class="$style.title">Тип</div>
        <div :class="$style.dropdownContainer">
          <GnzsDropdown :class="$style.dropdown" v-model="paymentType" :items="paymentTypes" :use-empty-item="false" />
        </div>
      </div>
      <div :class="$style.buttonContainer">
        <GnzsButton
          type="primary"
          :class="$style.button"
          @click="requestInvoice(paymentType)"
          :loading="invoiceRequesting"
          :disabled="!paymentType"
          >Запросить</GnzsButton
        >
      </div>
    </div>
    <div :class="$style.linkWrapper" v-if="paymentLink">
      <div :class="$style.linkCaption">Ссылка на оплату</div>
      <div :class="$style.linkContainer">
        <GnzsInput :disabled="true" :clipboardEnabled="true" :value="paymentLink" />
      </div>
    </div>
    <div :class="$style.wrapper">
      <div v-for="(doc, index) in documents" :key="index" :class="$style.container" :data-id="doc.id">
        <a :href="doc.gdi" :class="$style.infoContainer" target="_blank">
          <svg :class="$style.icon">
            <use :xlink:href="getIconId(doc.ext)"></use>
          </svg>
          <div :class="$style.titleContainer">
            <div :class="$style.title" :title="getDocName(doc)">{{ getDocName(doc) }}</div>
            <div :class="$style.subtitle" :title="doc.organization.name">{{ doc.organization.name }}</div>
            <div :class="$style.subtitle">{{ getDateCreation(doc) }}</div>
          </div>
        </a>
        <div :class="$style.contextMenuContainer">
          <GnzsContextMenuButton :items="getContextMenuItems(doc)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsInput from "@/gnzs-controls/Input";

  import moment from "moment";
  import GnzsContextMenuButton from "@/gnzs-controls/ContextMenuButton";

  export default {
    data() {
      return {
        paymentType: "card",
        paymentTypes: [
          {
            value: "card",
            title: "Ссылка",
          },
          {
            value: "invoice",
            title: "Счёт",
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        documents: "documents/invoiceItems",
        invoiceRequesting: "documents/invoiceRequesting",
        paymentLink: "documents/paymentLink",
      }),
    },
    methods: {
      ...mapActions({
        copyLinkToClipboard: "documents/copyLinkToClipboard",
        sendToEmail: "documents/sendToEmail",
        download: "documents/download",
        setDocumentToRemove: "documents/setDocumentToRemove",
        requestInvoice: "documents/requestInvoice",
      }),
      getDocName(doc) {
        return doc.name || `${doc.templateName} ${doc.number}`;
      },
      getDateCreation(doc) {
        if (doc.createdAt) {
          return "Создан " + moment(doc.createdAt).format("DD.MM.YYYY HH:mm");
        }
        return "";
      },
      getContextMenuItems(document) {
        return [
          {
            iconLink: "#gnzs-1c-icon__copy",
            text: "Скопировать ссылку",
            onClick: () => this.$store.dispatch("documents/copyLinkToClipboard", document),
          },
          {
            iconLink: "#gnzs-1c-icon__email",
            text: "Отправить по Email",
            onClick: () => this.$store.dispatch("documents/sendToEmail", document),
          },
          {
            iconLink: "#gnzs-1c-icon__download",
            text: "Скачать документ",
            onClick: () => this.$store.dispatch("documents/download", document),
          },
          {
            iconLink: "#gnzs-1c-icon__remove",
            text: "Удалить документ",
            onClick: () => this.openConfirmModal({ document }),
          },
        ];
      },
      getIconId(name) {
        switch (name) {
          case ".pdf":
            return "#gnzs-1c-icon__doc-pdf";
          case ".xlsx":
            return "#gnzs-1c-icon__doc-excel";
          case ".docx":
            return "#gnzs-1c-icon__doc-word";
          case ".xls":
            return "#gnzs-1c-icon__doc-excel";
          case ".doc":
            return "#gnzs-1c-icon__doc-word";
          case ".jpg":
            return "#gnzs-1c-icon__doc-image";
          case ".jpeg":
            return "#gnzs-1c-icon__doc-image";
          case ".png":
            return "#gnzs-1c-icon__doc-image";
        }
        return "#gnzs-1c-icon__doc-html";
      },
      createInvoice() {},
    },
    components: {
      GnzsContextMenuButton,
      GnzsDropdown,
      GnzsButton,
      GnzsInput,
    },
  };
</script>

<style lang="scss" module>
  @import "./invoices.scss";
</style>
